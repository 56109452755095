import { Injectable } from '@angular/core';
import { IProfile } from '../../models/profile.interface';
import { ApiClientService } from '../api.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ILesson, ILessonDTO, ILessonsResultDTO, ISemester, ISemesterDTO, ISemesterMonth } from '../../models/lesson.interface';
import { IResults } from '../../models/results.interface';
import { map } from 'rxjs/operators';
import { ProfileState } from '../../store/profile/profile.state';
import { parse } from 'date-fns';

@Injectable({
    providedIn: 'root',
})
export class LessonsService {
    protected _profile: IProfile;

    constructor(
        public api: ApiClientService,
        protected store: Store
    ) {}

    public get(id: number): Observable<ISemester[]> {
        return this.api.get<IResults<ILessonsResultDTO>>(`groups/${id}/lessons`).pipe(map(response => this.parse(response.results)));
    }

    public getByMonths(id: number): Observable<ISemesterMonth[]> {
        return this.api.get<IResults<ILessonsResultDTO>>(`groups/${id}/lessons`).pipe(map(response => this.parseToMonths(response.results)));
    }

    public getByList(id: number): Observable<ILesson[]> {
        return this.api.get<IResults<ILessonsResultDTO>>(`groups/${id}/lessons`).pipe(map(response => this.parseToList(response.results)));
    }

    public getOne(groupId: number, lessonId: number): Observable<ILesson> {
        return this.api.get<IResults<ILessonDTO>>(`groups/${groupId}/lessons/${lessonId}`).pipe(map(response => this.parseLesson(response.results)));
    }

    public parseToList(dto: ILessonsResultDTO): ILesson[] {
        return this.parseToMonths(dto).reduce((a, b) => {
            a.push(...b.lessons);
            return a;
        }, []);
    }

    public parseToMonths(dto: ILessonsResultDTO): ISemesterMonth[] {
        this._profile = this.store.selectSnapshot(ProfileState.profile);
        const months: ISemesterMonth[] = [];

        for (const key in dto) {
            const element = dto[key] as ISemesterDTO;

            for (const keyIn in element) {
                const lessons = element[keyIn];
                const month = this.parseMonth(lessons, keyIn, key);
                const existingMonth = months.find(m => m.month === month.month);

                if (existingMonth) {
                    existingMonth.lessons.push(...month.lessons);
                } else {
                    months.push(month);
                }
            }
        }

        return months;
    }

    public parse(dto: ILessonsResultDTO): ISemester[] {
        this._profile = this.store.selectSnapshot(ProfileState.profile);

        const semesters = [];

        if (!dto['1']) {
            semesters.push(null);
        }

        for (const key in dto) {
            const element = dto[key] as ISemesterDTO;
            const semester = {
                months: [],
            };

            for (const keyIn in element) {
                const lessons = element[keyIn];
                semester.months.push(this.parseMonth(lessons, keyIn, key));
            }

            semesters.push(semester);
        }

        if (dto[2] === undefined && semesters.length === 1) {
            semesters.push(null);
        }

        return semesters;
    }

    private parseMonth(lessons: ILessonDTO[], month: string, semester: string): ISemesterMonth {
        return {
            semester: parseInt(semester, 0),
            month: parse(month, 'yyyy-MM', new Date()),
            lessons: lessons.map(l => this.parseLesson(l)),
        };
    }

    public parseLesson(lessonDto: ILessonDTO, profile?: IProfile): ILesson {
        if (!profile) {
            profile = this._profile;
        }

        if (!lessonDto) {
            return null;
        }

        const lesson = {
            ...lessonDto,
            date: lessonDto.date,
            startHour: lessonDto.startHour,
            finishHour: lessonDto.finishHour,
            defaultDate: lessonDto.defaultDate,
            defaultStartHour: lessonDto.defaultStartHour,
            defaultFinishHour: lessonDto.defaultFinishHour,

            nextLessons: lessonDto.nextLessons,
            previousLesson: lessonDto.previousLesson,
            group: {
                ...lessonDto.group,
                color: lessonDto.group.color ? lessonDto.group.color.background : '#fff',
            },
        } as ILesson;

        if (lessonDto.group.substituteLector && this._profile && lessonDto.group.substituteLector.id === this._profile.user.id) {
            lesson.group.color = '#7510C7';
        }

        return lesson;
    }
}
